<div id="dashboard" class="alignDash">
  <div class="alignDash">
    <a #comission (click)="setTab('comission')" class="tab-link">
      <div class="content">
        <strong>{{ 'comission.current' | translate }}</strong>
      </div>
    </a>
    <a #unPaid (click)="setTab('unPaid')" class="tab-link mr-0" [ngClass]="activeTab === 'unPaid' ? 'tab-active' : ''">
      <div class="content">
        <strong>{{ 'comission.unPaid' | translate }}</strong>
      </div>
    </a>
  </div>
  <div class="alignDash">
    <div class="print">
      <button (click)="print()" class="tab-link">
        <div class="content">
          <app-print-icon></app-print-icon>
          <strong>
            {{ 'comission.print1' | translate }} <br />{{ 'comission.print2' | translate }}
          </strong>
        </div>
      </button>
    </div>
    <div class="balance" *ngIf="comissions">
      <span id="text">
        {{ 'comission.facturer' | translate }}
        {{ (comissions.TotalCommissions - comissions.TotalUnpaid) < 0 ? '0' : (comissions.TotalCommissions -
          comissions.TotalUnpaid) | number: '1.2-2' }}€ </span>
    </div>
  </div>
  <div class="alignDash forceAlign">
    <app-filter-icons></app-filter-icons>
  </div>
</div>

<app-comissions-unpaid *ngIf="activeTab === 'unPaid'" [comissions]="comissions"
  [activeFilter]="activeFilter"></app-comissions-unpaid>
<app-comissions-current *ngIf="activeTab === 'comission'" [comissions]="comissions"
  [activeFilter]="activeFilter"></app-comissions-current>