<div class="header-root">
  <div class="bottom-header noprint">
    <div class="header-container">
      <a (click)="toHomePage()" class="logo-link">
        <img
          class="small"
          src="assets/images/certinergie-icon-rvb.svg"
          alt="{{ 'header.link-certinergie-website' | translate }}"
        />
        <img
          class="complete"
          src="../../assets/images/certinergie-logo.svg"
          alt="{{ 'header.link-certinergie-website' | translate }}"
        />
      </a>

      <div>
        <div *ngIf="isTablette" class="nav-container" [ngClass]="open ? 'visible' : ''">
          <ng-container *ngIf="$isLoggedIn | async"
            ><button
              class="btn user-menu-button"
              [matMenuTriggerFor]="userMenu"
              matTooltip="{{ $userEmail | async }}"
              matTooltipPosition="right"
            >
              <svg
                class="user-icon"
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4"></path>
                <path class="accent" d="M15 19l2 2l4 -4"></path>
              </svg>
              <span class="user-label ellipsis">{{ $userEmail | async }}</span>
            </button>
            <mat-menu #userMenu="matMenu" xPosition="before">
              <button mat-menu-item (click)="logOut()" class=" mat-menu-icon">
                <fa-icon icon="power-off"></fa-icon>
                <span>{{ 'header.deconnection' | translate }}</span>
              </button>
            </mat-menu>
          </ng-container>

          <ng-container *ngIf="!($isLoggedIn | async)"
            ><button
              class="btn user-menu-button"
              [matMenuTriggerFor]="userMenu"
              matTooltip="{{ 'header.anonymous' | translate }}"
              matTooltipPosition="right"
            >
              <svg
                class="user-icon"
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5"></path>
                <path d="M19 22v.01"></path>
                <path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path>
              </svg>
              <span class="user-label ellipsis">
                {{ 'header.anonymous' | translate }}
              </span>
            </button>
            <mat-menu #userMenu="matMenu" xPosition="before">
              <button mat-menu-item (click)="openLoginDialog()" class=" mat-menu-icon">
                <fa-icon icon="user"></fa-icon>
                <span>{{ 'header.btn-login' | translate }}</span>
              </button>
            </mat-menu></ng-container
          >

          <nav class="action-side">
            <p *ngIf="$isLoggedIn | async" class="sidenav-section-title">
              {{ 'customer-space' | translate }}
            </p>

            <ul *ngIf="$isLoggedIn | async">
              <li>
                <a
                  mat-menu-item
                  [routerLink]="['/customer']"
                  (click)="open = false"
                  [ngClass]="activeNavLinkId === 'customer-space' ? 'active-navlink' : ''"
                >
                  <fa-icon class="action-side-icon" icon="briefcase"></fa-icon>
                  <span>{{ 'header.espace-client' | translate }}</span>
                </a>
              </li>
              <li>
                <a
                  mat-menu-item
                  (click)="toCommission(); open = false"
                  *ngIf="$hasCommission | async"
                  target="_blank"
                  [ngClass]="activeNavLinkId === 'commission' ? 'active-navlink' : ''"
                >
                  <fa-icon class="action-side-icon" icon="receipt"></fa-icon>
                  <span>{{ 'header.commission' | translate }}</span>
                </a>
              </li>
              <li>
                <button
                  mat-menu-item
                  (click)="toProfile(); open = false"
                  class=" mat-menu-icon"
                  [ngClass]="activeNavLinkId === 'profile' ? 'active-navlink' : ''"
                >
                  <fa-icon class="action-side-icon" icon="cog"></fa-icon>
                  <span>{{ 'header.profile' | translate }}</span>
                </button>
              </li>
              <li>
                <button
                  mat-menu-item
                  (click)="toStats(); open = false"
                  class=" mat-menu-icon"
                  [ngClass]="activeNavLinkId === 'stats' ? 'active-navlink' : ''"
                >
                  <svg
                    class="action-side-icon"
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z"
                    ></path>
                  </svg>
                  <span>{{ 'header.stats' | translate }}</span>
                </button>
              </li>
              <li>
                <button
                  mat-menu-item
                  *ngIf="userType === 1689"
                  (click)="toRates(); open = false"
                  class=" mat-menu-icon"
                  [ngClass]="activeNavLinkId === 'rates' ? 'active-navlink' : ''"
                >
                  <fa-icon class="action-side-icon" icon="tags"></fa-icon>
                  <span>{{ 'Rates.rate' | translate }}</span>
                </button>
              </li>
              <li>
                <button
                  mat-menu-item
                  (click)="toOrdering()"
                  class=" mat-menu-icon highlight"
                  [ngClass]="activeNavLinkId === 'order' ? 'active-navlink' : ''"
                >
                  <fa-icon icon="plus" class="action-side-icon"></fa-icon>
                  <span>{{ 'header.order' | translate }}</span>
                </button>
              </li>

              <div class="separator"></div>

              <li>
                <button mat-menu-item (click)="logOut(); open = false" class=" mat-menu-icon">
                  <fa-icon class="action-side-icon" icon="power-off"></fa-icon>
                  <span>{{ 'header.deconnection' | translate }}</span>
                </button>
              </li>
            </ul>

            <ul *ngIf="!($isLoggedIn | async)">
              <li>
                <button
                  mat-menu-item
                  (click)="toOrdering()"
                  class=" mat-menu-icon highlight"
                  [ngClass]="activeNavLinkId === 'order' ? 'active-navlink' : ''"
                >
                  <fa-icon icon="plus" class="action-side-icon"></fa-icon>
                  <span>{{ 'header.order' | translate }}</span>
                </button>
              </li>

              <div class="separator"></div>

              <li>
                <button mat-menu-item (click)="openLoginDialog(); open = false">
                  <fa-icon class="action-side-icon" icon="user"></fa-icon>
                  <span>{{ 'header.btn-login' | translate }}</span>
                </button>
              </li>
            </ul>

            <div class="separator"></div>

            <ul class="languages-list">
              <li *ngFor="let language of languages">
                <button (click)="switchLanguage(language.id); open = false" mat-menu-item>
                  <img [src]="language.flagImgUrl" alt="" height="20" />
                  <span>{{ language.name }}</span>
                  <fa-icon *ngIf="currentLang === language.id" icon="check"></fa-icon>
                </button>
              </li>
            </ul>
          </nav>

          <div class="nav-footer">
            <h6>{{ 'footer.section-help.title' | translate }}</h6>
            <a href="mailto: info@certinergie.be">info@certinergie.be</a>
            <a href="tel:028802171">{{ phoneNumber }}</a>
            <a href="www.certinergie.be">www.certinergie.be</a>
            <div class="social-link">
              <a href="https://www.facebook.com/certinergie/" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g id="Groupe_225" data-name="Groupe 225" transform="translate(-266.345 -80.114)">
                    <path
                      id="Tracé_10"
                      data-name="Tracé 10"
                      d="M278.345,104.114h0a12,12,0,0,1-12-12h0a12,12,0,0,1,12-12h0a12,12,0,0,1,12,12h0A12,12,0,0,1,278.345,104.114Z"
                      transform="translate(0 0)"
                      fill="#fff"
                      class="white-part"
                    />
                    <path
                      id="Tracé_11"
                      data-name="Tracé 11"
                      d="M293.763,108.556h3v-7.188h2.092l.223-2.407h-2.315V97.59c0-.568.119-.792.692-.792h1.623V94.3H297c-2.231,0-3.237.941-3.237,2.742v1.919H292.2V101.4h1.56Z"
                      transform="translate(-17.294 -9.314)"
                      fill="#336"
                    />
                  </g>
                </svg>
              </a>
              <a href="https://twitter.com/Certinergie" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="24.094" height="24.094" viewBox="0 0 24.094 24.094">
                  <g id="Groupe_228" data-name="Groupe 228" transform="translate(-57.467 -80.114)">
                    <path
                      id="Tracé_15"
                      data-name="Tracé 15"
                      d="M69.514,104.208h0A12.047,12.047,0,0,1,57.467,92.161h0A12.047,12.047,0,0,1,69.514,80.114h0A12.047,12.047,0,0,1,81.561,92.161h0A12.047,12.047,0,0,1,69.514,104.208Z"
                      fill="#fff"
                      class="white-part"
                    />
                    <path
                      id="Tracé_16"
                      data-name="Tracé 16"
                      d="M74.222,109.7a7.49,7.49,0,0,0,11.523-6.651,5.334,5.334,0,0,0,1.314-1.363,5.248,5.248,0,0,1-1.512.415,2.638,2.638,0,0,0,1.158-1.457,5.283,5.283,0,0,1-1.672.64,2.635,2.635,0,0,0-4.488,2.4,7.475,7.475,0,0,1-5.428-2.752,2.637,2.637,0,0,0,.815,3.517,2.631,2.631,0,0,1-1.193-.33,2.637,2.637,0,0,0,2.113,2.616,2.637,2.637,0,0,1-1.189.045,2.634,2.634,0,0,0,2.46,1.829A5.293,5.293,0,0,1,74.222,109.7Z"
                      transform="translate(-11.127 -13.504)"
                      fill="#336"
                    />
                  </g>
                </svg>
              </a>
              <a href="https://www.linkedin.com/company/1444134" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="24.094" height="24.094" viewBox="0 0 24.094 24.094">
                  <g id="Groupe_231" data-name="Groupe 231" transform="translate(-162.5 -181.737)">
                    <path
                      id="Tracé_17"
                      data-name="Tracé 17"
                      d="M174.547,205.831h0A12.047,12.047,0,0,1,162.5,193.784h0a12.047,12.047,0,0,1,12.047-12.047h0a12.047,12.047,0,0,1,12.047,12.047h0A12.047,12.047,0,0,1,174.547,205.831Z"
                      fill="#fff"
                      class="white-part"
                    />
                    <g id="Groupe_230" data-name="Groupe 230" transform="translate(168.845 187.071)">
                      <g id="Groupe_229" data-name="Groupe 229" transform="translate(0 0)">
                        <rect
                          id="Rectangle_1425"
                          data-name="Rectangle 1425"
                          width="2.493"
                          height="8.055"
                          transform="translate(0.24 4.027)"
                          fill="#336"
                        />
                        <path
                          id="Tracé_18"
                          data-name="Tracé 18"
                          d="M182.862,200.589a1.486,1.486,0,1,0-1.474-1.486A1.481,1.481,0,0,0,182.862,200.589Z"
                          transform="translate(-181.388 -197.617)"
                          fill="#336"
                        />
                      </g>
                      <path
                        id="Tracé_19"
                        data-name="Tracé 19"
                        d="M196.66,212.946c0-1.132.521-1.807,1.519-1.807.917,0,1.357.648,1.357,1.807v4.228h2.482v-5.1c0-2.157-1.223-3.2-2.931-3.2a2.808,2.808,0,0,0-2.427,1.331V209.12h-2.392v8.054h2.392Z"
                        transform="translate(-189.941 -205.093)"
                        fill="#336"
                      />
                    </g>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div *ngIf="isTablette" class="login-status">
          <ng-container *ngIf="$isLoggedIn | async">
            <svg
              class="user-icon"
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
              <path d="M6 21v-2a4 4 0 0 1 4 -4h4"></path>
              <path class="accent" d="M15 19l2 2l4 -4"></path>
            </svg>
            <span class="user-label ellipsis">{{ $userEmail | async }}</span>
          </ng-container>

          <ng-container *ngIf="!($isLoggedIn | async)">
            <svg
              class="user-icon"
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
              <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5"></path>
              <path d="M19 22v.01"></path>
              <path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path>
            </svg>
            <span class="user-label ellipsis">
              {{ 'header.anonymous' | translate }}
            </span>
          </ng-container>
        </div>

        <button *ngIf="isTablette" class="burger" (click)="open = !open" [ngClass]="open ? 'active' : ''">
          <div>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="sidenav-layout-container">
  <div class="sidenav" [ngClass]="!isTablette ? 'opened' : ''" [attr.aria-hidden]="isTablette">
    <div class="sidenav-content">
      <div class="user-profile">
        <ng-container *ngIf="$isLoggedIn | async"
          ><button
            class="btn user-menu-button"
            [matMenuTriggerFor]="userMenu"
            matTooltip="{{ $userEmail | async }}"
            matTooltipPosition="right"
          >
            <svg
              class="user-icon"
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
              <path d="M6 21v-2a4 4 0 0 1 4 -4h4"></path>
              <path class="accent" d="M15 19l2 2l4 -4"></path>
            </svg>
            <span class="user-label ellipsis">{{ $userEmail | async }}</span>
          </button>
          <mat-menu #userMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="logOut()" class=" mat-menu-icon">
              <fa-icon icon="power-off"></fa-icon>
              <span>{{ 'header.deconnection' | translate }}</span>
            </button>
          </mat-menu>
        </ng-container>

        <ng-container *ngIf="!($isLoggedIn | async)"
          ><button
            class="btn user-menu-button"
            [matMenuTriggerFor]="userMenu"
            matTooltip="{{ 'header.anonymous' | translate }}"
            matTooltipPosition="right"
          >
            <svg
              class="user-icon"
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
              <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5"></path>
              <path d="M19 22v.01"></path>
              <path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path>
            </svg>
            <span class="user-label ellipsis">
              {{ 'header.anonymous' | translate }}
            </span>
          </button>
          <mat-menu #userMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="openLoginDialog()" class=" mat-menu-icon">
              <fa-icon icon="user"></fa-icon>
              <span>{{ 'header.btn-login' | translate }}</span>
            </button>
          </mat-menu></ng-container
        >
      </div>

      <ng-container *ngIf="$isLoggedIn | async">
        <p class="sidenav-section-title">{{ 'customer-space' | translate }}</p>

        <button
          mat-menu-item
          [ngClass]="activeNavLinkId === 'customer-space' ? 'active-navlink' : ''"
          [routerLink]="['/customer']"
          matTooltip="{{ 'header.espace-client' | translate }}"
          matTooltipPosition="right"
          [matTooltipDisabled]="isBpLarge"
        >
          <fa-icon icon="briefcase"></fa-icon>
          <span>{{ 'header.espace-client' | translate }}</span>
        </button>
        <button
          mat-menu-item
          (click)="toCommission()"
          [ngClass]="activeNavLinkId === 'commision' ? 'active-navlink' : ''"
          *ngIf="$hasCommission | async"
          target="_blank"
          matTooltip="{{ 'header.commission' | translate }}"
          matTooltipPosition="right"
          [matTooltipDisabled]="isBpLarge"
        >
          <fa-icon icon="receipt"></fa-icon>
          <span>{{ 'header.commission' | translate }}</span>
        </button>
        <!-- <button
          mat-menu-item
          class="notification-btn mat-menu-icon"
          (click)="toNotifications()"
          [ngClass]="activeNavLinkId === 'notifications' ? 'active-navlink' : ''"
          matTooltip="{{ 'header.notifications' | translate }}"
          matTooltipPosition="right"
          [matTooltipDisabled]="isBpLarge"
          matBadge="8"
          matBadgeSize="small"
          [matBadgeHidden]="isBpLarge"
        >
          <fa-icon [icon]="['fas', 'bell']"></fa-icon>
          <span>{{ 'header.notifications' | translate }}</span>
        </button> -->
        <button
          mat-menu-item
          (click)="toProfile()"
          class="mat-menu-icon"
          [ngClass]="activeNavLinkId === 'profile' ? 'active-navlink' : ''"
          matTooltip="{{ 'header.profile' | translate }}"
          matTooltipPosition="left"
          [matTooltipDisabled]="isBpLarge"
        >
          <fa-icon icon="cog"></fa-icon>
          <span>{{ 'header.profile' | translate }}</span>
        </button>
        <button
          mat-menu-item
          (click)="toStats()"
          class=" mat-menu-icon"
          [ngClass]="activeNavLinkId === 'stats' ? 'active-navlink' : ''"
          matTooltip="{{ 'header.stats' | translate }}"
          matTooltipPosition="right"
          [matTooltipDisabled]="isBpLarge"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z"
            ></path>
          </svg>
          <span>{{ 'header.stats' | translate }}</span>
        </button>
        <button
          mat-menu-item
          *ngIf="userType === 1689"
          (click)="toRates()"
          class=" mat-menu-icon"
          [ngClass]="activeNavLinkId === 'rates' ? 'active-navlink' : ''"
          matTooltip="{{ 'Rates.rate' | translate }}"
          matTooltipPosition="right"
          [matTooltipDisabled]="isBpLarge"
        >
          <fa-icon icon="tags"></fa-icon>
          <span>{{ 'Rates.rate' | translate }}</span>
        </button>
      </ng-container>

      <button
        mat-menu-item
        (click)="toOrdering()"
        class=" mat-menu-icon highlight"
        [ngClass]="activeNavLinkId === 'order' ? 'active-navlink' : ''"
        matTooltip="{{ 'header.devis-btn' | translate }}"
        matTooltipPosition="right"
        [matTooltipDisabled]="isBpLarge"
      >
        <fa-icon icon="plus"></fa-icon>
        <span>{{ 'header.order' | translate }}</span>
      </button>

      <div class="separator"></div>

      <button
        *ngIf="!($isLoggedIn | async)"
        mat-menu-item
        (click)="openLoginDialog(); open = false"
        matTooltip="{{ 'header.btn-login' | translate }}"
        matTooltipPosition="right"
        [matTooltipDisabled]="isBpLarge"
      >
        <fa-icon [icon]="['fas', 'user']"></fa-icon>
        <span>{{ 'header.btn-login' | translate }}</span>
      </button>

      <button
        *ngIf="$isLoggedIn | async"
        mat-menu-item
        (click)="logOut()"
        matTooltip="{{ 'header.deconnection' | translate }}"
        matTooltipPosition="right"
        [matTooltipDisabled]="isBpLarge"
      >
        <fa-icon [icon]="['fas', 'power-off']"></fa-icon>
        <span>{{ 'header.deconnection' | translate }}</span>
      </button>

      <button
        mat-menu-item
        class=" mat-menu-icon"
        [matMenuTriggerFor]="langageMenu"
        matTooltip="{{ currentLang | uppercase }}"
        matTooltipPosition="right"
        [matTooltipDisabled]="isBpLarge"
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          style="vertical-align: text-top;"
        >
          <path
            fill="none"
            stroke-width="2"
            d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M12,23 C15,23 16,18 16,12 C16,6 15,1 12,1 C9,1 8,6 8,12 C8,18 9,23 12,23 Z M2,16 L22,16 M2,8 L22,8"
          ></path>
        </svg>
        <span>{{ currentLang | uppercase }}</span
        >&nbsp;
        <fa-icon icon="angle-down"></fa-icon>
      </button>
      <mat-menu #langageMenu="matMenu" class="menu-langage">
        <ul class="languages-list">
          <li *ngFor="let language of languages">
            <button (click)="switchLanguage(language.id)" mat-menu-item>
              <img [src]="language.flagImgUrl" alt="" height="14" />
              <span>{{ language.name }}</span>
              <fa-icon *ngIf="currentLang === language.id" icon="check"></fa-icon>
            </button>
          </li>
        </ul>
      </mat-menu>
    </div>
  </div>

  <div class="sidenav-layout-content"><ng-content></ng-content></div>
</div>
