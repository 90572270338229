import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, Input, AfterContentInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { DataService } from '../core/services/data.service';
import { BreakpointObserver } from '@angular/cdk/layout';

import { AppState } from '../interfaces';
import * as orderSelector from '../core/store/order/order.selectors';
import * as uiSelector from '../core/store/ui/ui.selectors';

import {
  filterNewUserTypes,
  defaultRegion,
  forceConnection,
  hasImmoAccessProject,
  hasAccessAllPack,
  themeColor
} from '../core/data/theme-config';

import {
  SetEstateType,
  SetRegion,
  SetNumberOfAppartments,
  SetServices,
  ToggleOnlinePayment,
  SetPromoCode,
  ToggleUrgence,
  SetCommission,
  SetEstateStyle,
  SetGazChoice,
  SetPebNb,
  ResetOrderState,
  SetZipCode,
  SetReason,
  SetClientType,
  ResetPack,
  SetImmoPack,
  SetImmoPackIds,
  SetVenteType,
  SetImmoOrCtrl,
  IOrder,
  SetSplitInvoice
} from '../core/store/order';
import { Observable, combineLatest, Subscription, firstValueFrom } from 'rxjs';

import * as estates from '../core/data/estates';
import { Product } from '../core/models/product';

import * as PRODUCTS from '../core/data/products';
import { Reduction } from '../core/models/reduction';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { LoginDialogComponent } from '../dialogs/login-dialog/login-dialog.component';
import { SpecificRequestDialogComponent } from '../dialogs/specific-request-dialog/specific-request-dialog.component';
import { SummaryConfirmDialogComponent } from '../dialogs/summary-confirm-dialog/summary-confirm-dialog.component';
import { FormGroup, Validators, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { Role, UserType, ReasonType } from '../core/models/user';
import {
  SetShowPacks,
  SetStep1Valid,
  SetPacksWithPrices,
  ShowMoreServices,
  SetPacksWithPricesNoFilt
} from '../core/store/ui';
import { PackDB } from '../core/models/PackDB';
import { descriptifPacks, services, immoServices, servicesSupp, panneauxServices, mdnServices } from '../core/data';
import { DESCRIPTIF } from '../core/data/products';
import { ActivatedRoute, Router } from '@angular/router';
import { Region } from '../core/models/region';
import { map, debounceTime, take } from 'rxjs/operators';
import {
  displayRegionSelection,
  step1TitleImmo,
  step1SubTitle,
  headerShowPhone,
  showFinalOptions,
  projectID
} from '../core/data/theme-config';
import { userTypePartAndPro, userTypePartAndNotary } from '../core/data/newUserTypes';
import { immoOrCtrlOptions } from '../core/data/immoOrCtrlOptions';
import { mdnOptions } from '../core/data/immoOrCtrlOptions';
import { nvnOptions } from '../core/data/immoOrCtrlOptions';
import { ventes } from '../core/data/ventes';
import { reasons } from '../core/data/reasons';
import { imgagesFolder } from '../core/services/globals';
import { LoggerService } from '../core/services/logger.service';
import { PrintRequestDialogComponent } from '../dialogs/print-request-dialog/print-request-dialog.component';
import { VisitRequestDialogComponent } from '../dialogs/visit-request-dialog/visit-request-dialog.component';
import { VisitContact } from '../core/models/visitContact';
import { VenteInfo } from '../core/models/venteInfo';
import { PosterRequestDialogComponent } from '../dialogs/poster-request-dialog/poster-request-dialog.component';
import { SignsRequestDialogComponent } from '../dialogs/signs-request-dialog/signs-request-dialog.component';
import { PaperRequestDialogComponent } from '../dialogs/paper-request-dialog/paper-request-dialog.component';
import { ServicesChoiceDialogComponent } from '../dialogs/services-choice-dialog/services-choice-dialog.component';
import { PrintFullpressRequestDialogComponent } from '../dialogs/print-fullpress-request-dialog/print-fullpress-request-dialog.component';
import { PanelXlRequestDialogComponent } from '../dialogs/panel-xl-request-dialog/panel-xl-request-dialog.component';
import { SpecificPrintRequestDialogComponent } from '../dialogs/specific-print-request-dialog/specific-print-request-dialog.component';
import { AuthService } from '../core/services/auth.service';
import { DOCUMENT } from '@angular/common';
import { CustomRequestDialogComponent } from '../dialogs/custom-request-dialog/custom-request-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ventePubChoices, venteBidditChoices } from '../core/models/venteChoices';
import { ServicesDetailsDialogComponent } from '../dialogs/services-details-dialog/services-details-dialog.component';
import { GlobalMessageComponent } from '../dialogs/global-message/global-message.component';
import { PanelRequestDialogComponent } from '../dialogs/panel-request-dialog/panel-request-dialog.component';
import { MessageDialogComponent } from '../dialogs/message-dialog/message-dialog.component';
import { VisitZyvaRequestDialogComponent } from '../dialogs/visit-zyva-request-dialog/visit-zyva-request-dialog.component';
import { GtmService } from '../core/services/google-tag-manager.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss']
})
export class Step1Component implements OnInit, AfterViewChecked, AfterContentInit {
  assetsFolder = 'assets/images/';
  descriptifPacks: number[];

  @Input() packs: PackDB[];
  @Input() stepper: MatStepper;
  regions;
  estateTypes;
  estateStyles;
  noPlaceHolderEstateStyles;
  immoPacks;
  houseOptions: HouseOption[];
  nonResOptions: HouseOption[];
  appartOptions;
  immeubleOptions;
  elecOptions;
  gazOptions;
  promoCode: string;
  pebNb = '';
  priceWithoutReduction: number;
  reduction: number;
  howManyControlSelected: number;
  howManyImmoSelected: number;
  reductionPromoCode: string;
  estateType: number;
  pebPartiel = false;
  wantsIso = true;
  hasImmoAccess: boolean;
  hasPanel = false;
  hasPanelSupport = false;
  hasBidditPanel = false;
  hasBidditPanelSupport = false;
  showFinalOptions = showFinalOptions;
  showMoreServices: boolean;
  visitLength;
  reason: number;
  venteType: number;
  PRODUCTS = PRODUCTS;
  isAvailableZipFlanders = true;
  reducMoreDetails = false;

  $venteInfo: Observable<VenteInfo>;

  mdnService =
    projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege' ? mdnServices : [];

  get isHainautOrNamurorNVN() {
    return projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege';
  }

  venteInfo: VenteInfo;

  displayRegionSelection = displayRegionSelection;
  step1TitleImmo = step1TitleImmo;
  projectID = projectID !== 'default' ? '-' + projectID : '';

  hasImmoAccessProject = hasImmoAccessProject;
  hasAccessAllPack = hasAccessAllPack;

  succintStep1 = false;
  step1SubTitle = step1SubTitle;
  headerShowPhone = headerShowPhone;

  houseControl = new FormControl();
  appartControl = new FormControl();
  buildingControl = new FormControl();
  situationControl = new FormControl();
  nonResControl = new FormControl();

  reasons = reasons;
  venteTypes = ventes;
  UserTypes = UserType;
  filterNewUserTypes = filterNewUserTypes;
  clientTypes: { id: number | string; txt: string; fileName: string }[];
  immoOrCtrlOptions: { id: number | string; txt: string; fileName: string }[];
  mdnOptions: { id: number | string; txt: string; fileName: string }[];
  nvnOptions: { id: number | string; txt: string; fileName: string }[];
  clientTypeIndex: number;
  reasonIndex: number;
  clientType: number;

  step1FormGroup: FormGroup;

  ventePubChoices = ventePubChoices;
  venteBidditChoices = venteBidditChoices;

  products: number[];
  productsDB: Product[];
  order: IOrder;

  $selectedRegion: Observable<number>;
  $selectedEstateType: Observable<number>;
  $selectedEstateStyle: Observable<number>;
  $selectedImmoPack: Observable<number>;
  $selectedUserType: Observable<number>;
  $nbOfAppartments: Observable<number>;
  $filteredServices: Observable<any>;
  $filteredImmoServices: Observable<any>;
  $filteredPanneauxServices: Observable<any>;
  $filteredPublicationsServices: Observable<any>;
  $products: Observable<Product[]>;
  $services: Observable<number[]>;
  $reductions: Observable<Reduction[]>;
  $onlinePayment: Observable<boolean>;
  $urgence: Observable<boolean>;
  $userID: Observable<string>;
  $isLoggedIn: Observable<boolean>;
  $hasImmoAccess: Observable<boolean>;
  $reduction: Observable<number>;
  $reductionWithoutComm: Observable<number>;
  $commission: Observable<number>;
  $nbElec: Observable<number>;
  $nbGaz: Observable<number>;
  $gazChoice: Observable<number>;
  $hasCommission: Observable<boolean>;
  $userRoles: Observable<Role[]>;
  $showPacks: Observable<boolean>;
  $isMobile: Observable<boolean>;
  $immoPacksWithPrices: any;
  $userType: Observable<UserType>;
  $promocodeID: Observable<string>;
  $immoPacks: Observable<PackDB[]>;
  $region: Observable<Region>;
  $hasElec: Observable<boolean>;
  $hasPeb: Observable<boolean>;
  $hasAudit: Observable<boolean>;
  $nbOfIso: Observable<number>;
  $reason: Observable<number>;
  $venteType: Observable<number>;
  $clientType: Observable<number>;
  $immoOrCtrlSelected: Observable<number>;
  $showMoreServices: Observable<boolean>;
  $venteStatus: Observable<{ id: number; name: string }>;
  $hasPack: Observable<boolean>;
  $packID: Observable<number>;
  $immoPackIds: Observable<number[]>;
  $pricingProducts: Observable<Product[]>;
  $packProducts: Observable<Product[]>;
  $packPrice: Observable<number>;
  $packName: Observable<string>;
  $zipCode: Observable<string>;
  $hasGazCerga: Observable<Boolean>;

  $ventePubSub: Subscription;
  $venteGreSub: Subscription;
  $venteBidditSub: Subscription;

  userID: string;
  packID: number;
  currentRegionId: number;
  currentEstateStyle: number;

  isFromNVN: boolean;
  isFromHainaut: boolean;
  isFromNamur: boolean;

  maison5: HouseOption;
  maison6: HouseOption;

  hasPeb: boolean;
  hasPebPartiel: boolean;
  hasElec: boolean;
  hasCiterne: boolean;
  hasPlan: boolean;
  hasGaz: boolean;

  nbOfApp: number;

  isMobile: boolean;
  isLoggedIn: boolean;
  immopacksWithPrices: any;

  estates = ['m', 'a', 'i'];
  showMorePack = false;

  filteredServices: any;
  filteredImmoServices: any;
  controlLength: number;
  immoLength: number;

  visitContact: VisitContact;
  $isAgency: Observable<boolean>;
  $isNotary: Observable<boolean>;
  $isInstallateur: Observable<boolean>;
  userType: number;
  svgPrimaryColor = {
    fill: themeColor
  };
  hasCompletedStep1: boolean = false;

  constructor(
    private store: Store<AppState>,
    public dataService: DataService,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private logger: LoggerService,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private gtmService: GtmService,
    private snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.store.select(orderSelector.getServices).subscribe(data => {
      this.products = data;
      this.hasPanelSupport = data.some(p => p === PRODUCTS.PANEL_SUPPORT);
      this.hasPanel = data.some(p => p === PRODUCTS.PANEL || p === PRODUCTS.PANEL_SUPPORT);
      this.hasBidditPanelSupport = data.some(p => p === PRODUCTS.PANELBIDDIT_SUPPORT);
      this.hasBidditPanel = data.some(p => p === PRODUCTS.PANELBIDDIT || p === PRODUCTS.PANELBIDDIT_SUPPORT);
      this.step1FormGroup = this._formBuilder.group({
        validStep: [this.products.length, Validators.min(1)]
      });

      this.store.dispatch(new SetStep1Valid(this.step1FormGroup.get('validStep').valid));
    });

    this.$venteInfo = this.store.select(orderSelector.getVenteInfo);
    this.$venteInfo.subscribe(v => {
      this.venteInfo = v;
    });

    this.descriptifPacks = descriptifPacks;
    this.checkQueryParams();

    if (projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege') {
      this.setRegion(defaultRegion);
    }
  }

  ngOnInit(): void {
    this.$immoPacks = this.dataService?.getPacksFromAPI();
    this.store?.select(orderSelector.getUserType).subscribe(u => (this.userType = u));
    this.store.select(orderSelector.getOrder).subscribe(o => (this.order = o));
    this.$selectedRegion = this.store.select(orderSelector.getRegion);
    this.$selectedEstateType = this.store.select(orderSelector.getEstateID);
    this.$selectedEstateStyle = this.store.select(orderSelector.getEstateStyle);
    this.$nbOfAppartments = this.store.select(orderSelector.getNbOfAppartments);
    this.$selectedImmoPack = this.store.select(orderSelector.getImmoPack);
    this.$packName = this.store.select(orderSelector.getPackName);
    this.$immoPackIds = this.store.select(orderSelector.getImmoPackIds);
    this.$filteredServices = this.store.select(orderSelector.getFilteredServices);
    this.$filteredImmoServices = this.store.select(orderSelector.getFilteredImmoServices);
    this.$reductions = this.store.select(orderSelector.getReductions);
    this.$filteredPanneauxServices = this.store.select(orderSelector.getFilteredPanneauxServices);
    this.$filteredPublicationsServices = this.store.select(orderSelector.getFilteredPublicationsServices);
    this.$products = this.store.select(orderSelector.getProducts);
    this.$pricingProducts = combineLatest([this.$products, this.$immoPackIds]).pipe(
      map(values => {
        const prods = values[0];
        const packIds = values[1];
        return prods.filter(pr => !packIds.find(id => id === pr.id));
      })
    );
    this.$packProducts = combineLatest([this.$products, this.$immoPackIds, this.$reductions]).pipe(
      map(values => {
        const prods = values[0];
        const packIds = values[1];
        const packReducPrice = values[2].reduce((acc, cur) => +cur.price + acc, 0);
        const reduc =
          (projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege') &&
          packReducPrice
            ? new Product(-99, +packReducPrice * -1, 0)
            : null;
        const filteredProds = prods.filter(pr => !!packIds.find(id => id === pr.id));

        return filteredProds.length > 0 && reduc ? [...filteredProds, reduc] : filteredProds;
      })
    );
    this.$packPrice = this.$packProducts
      .pipe(map(prs => prs.map(pr => +pr.price)))
      .pipe(map(p => p.reduce((acc, val) => acc + val, 0)));
    this.$onlinePayment = this.store.select(orderSelector.getOnlinePayment);
    this.$urgence = this.store.select(orderSelector.getUrgence);
    this.$userID = this.store.select(orderSelector.getUserID);
    this.$commission = this.store.select(orderSelector.getCommission);
    this.$reductionWithoutComm = this.store.select(orderSelector.getReductionWithoutComm);
    this.$reduction = this.store.select(orderSelector.getReduction);
    this.$nbElec = this.store.select(orderSelector.nbOfElec);
    this.$nbOfIso = this.store.select(orderSelector.getNbOfIso);
    this.$nbGaz = this.store.select(orderSelector.nbOfGaz);
    this.$hasElec = this.store.select(orderSelector.hasElec);
    this.$hasPeb = this.store.select(orderSelector.hasPeb);
    this.$hasAudit = this.store.select(orderSelector.hasAudit);
    this.$gazChoice = this.store.select(orderSelector.gazChoice);
    this.$hasImmoAccess = this.store.select(orderSelector.getHasImmoAccess);
    this.$hasCommission = this.store.select(orderSelector.getHasCommission);
    this.$userRoles = this.store.select(orderSelector.getUserRoles);
    this.$showPacks = this.store.select(uiSelector.getShowPacks);
    this.$isMobile = this.store.select(uiSelector.getIsMobile);
    this.$immoPacksWithPrices = this.store.select(uiSelector.getPacksWithPrices);
    this.$services = this.store.select(orderSelector.getServices);
    this.$userType = this.store.select(orderSelector.getUserType);
    this.$reason = this.store.select(orderSelector.getReason);
    this.$venteType = this.store.select(orderSelector.getVenteType);
    this.$clientType = this.store.select(orderSelector.getClientType);
    this.$immoOrCtrlSelected = this.store.select(orderSelector.getImmoOrCtrl);
    this.$zipCode = this.store.select(orderSelector.getAddress).pipe(map(ad => ad.zip));

    this.authService.isFromNVN.subscribe(b => (this.isFromNVN = b));
    this.authService.isFromNamur.subscribe(b => (this.isFromNamur = b));
    this.authService.isFromHainaut.subscribe(b => (this.isFromHainaut = b));

    this.$reduction.subscribe(reduc => (this.reduction = reduc));
    this.$promocodeID = this.store.select(orderSelector.getPromoCodeID);
    this.$region = this.store.select(orderSelector.getRegionInfo);
    this.$packID = this.store.select(orderSelector.getPackID);
    this.$hasPack = this.$packID.pipe(map(p => p && p !== -1));
    this.$packID.subscribe(p => {
      this.packID = p;
      this.dataService.getProductsForPack(p + '').subscribe(se => {
        const serv = [...se];
        const descriptifPack = this.descriptifPacks.some(packId => packId === p);
        if (descriptifPack) {
          serv.push(DESCRIPTIF);
          if (projectID === 'nvn') {
            serv.push(PRODUCTS.DESCRIPTIF_URBA);
          }
          serv.push(PRODUCTS.PLAQUETTE);
          serv.push(PRODUCTS.LIVRET);
          serv.push(PRODUCTS.AFFICHETTE);
        }
        this.store.dispatch(new SetImmoPackIds(serv));
      });
    });
    this.$userID.subscribe(uid => (this.userID = uid));
    this.$hasImmoAccess.subscribe(i => (this.hasImmoAccess = i));
    this.$showMoreServices = this.store.select(uiSelector.getShowMoreServices);
    this.store.select(orderSelector.pebNb).subscribe(nb => {
      this.pebNb = nb;
    });
    this.store.select(orderSelector.getVisitContact).subscribe(v => (this.visitContact = v));
    this.$reason.subscribe(r => (this.reason = r));
    this.$venteType.subscribe(v => (this.venteType = v));

    this.$showMoreServices.subscribe(bool => (this.showMoreServices = bool));

    this.clientTypes = projectID === 'default' ? userTypePartAndPro : userTypePartAndNotary;
    this.immoOrCtrlOptions = immoOrCtrlOptions;
    this.mdnOptions = mdnOptions;
    this.nvnOptions = nvnOptions;
    this.$isAgency = this.authService.isAgency;
    this.$isNotary = this.authService.isNotary;
    this.$isInstallateur = this.authService.isInstallateur;

    this.situationControl.setValidators(this.inputZipCode.bind(this));
    this.onZipChanges();
    this.$zipCode.subscribe(zip => {
      if (+this.situationControl.value !== +zip) {
        this.situationControl.patchValue(zip);
      }
    });

    this.$nbOfAppartments.subscribe(nb => (this.nbOfApp = nb));

    combineLatest(this.$nbOfAppartments, this.$hasPeb, this.$selectedEstateStyle)
      .pipe(map(results => ({ nbOfApp: results[0], hasPeb: results[1] })))
      .subscribe(v => {
        if (v.nbOfApp > 0 && this.estateType === 2) {
          if (v.hasPeb) {
            this.setPebSupp(v.nbOfApp);
          }
        }
      });

    combineLatest(this.$nbOfAppartments, this.$hasAudit, this.$selectedEstateStyle)
      .pipe(map(results => ({ nbOfApp: results[0], hasAudit: results[1] })))
      .subscribe(v => {
        if (v.nbOfApp > 0 && this.estateType === 2) {
          if (v.hasAudit) {
            this.setAuditSupp(v.nbOfApp);
          }
        }
      });

    combineLatest(this.$nbOfAppartments, this.$hasElec, this.$selectedEstateStyle)
      .pipe(map(results => ({ nbOfApp: results[0], hasElec: results[1] })))
      .subscribe(v => {
        if (v.nbOfApp > 0 && this.estateType === 2) {
          if (v.hasElec) {
            this.setElecSupp(v.nbOfApp);
          }
        }
      });

    this.store.select(orderSelector.hasPeb).subscribe(bool => (this.hasPeb = bool));
    this.store.select(orderSelector.hasPebPartiel).subscribe(bool => {
      this.hasPebPartiel = bool;
      if (bool) {
        this.pebPartiel = true;
      }
    });
    this.store.select(orderSelector.hasElec).subscribe(bool => (this.hasElec = bool));
    this.store.select(orderSelector.hasCiterne).subscribe(bool => (this.hasCiterne = bool));
    this.store.select(orderSelector.hasPlan).subscribe(bool => (this.hasPlan = bool));
    this.store.select(orderSelector.hasGaz).subscribe(bool => (this.hasGaz = bool));
    this.store.select(orderSelector.getPriceWithoutReduction).subscribe(price => (this.priceWithoutReduction = price));
    this.store.select(orderSelector.getPromoCode).subscribe(data => (this.promoCode = data));
    this.store.select(orderSelector.getEstateStyle).subscribe(data => (this.currentEstateStyle = data));
    this.store.select(orderSelector.getVisitsLength).subscribe(data => (this.visitLength = data));
    this.$hasGazCerga = this.store
      .select(orderSelector.hasGaz)
      .pipe(map(hasGaz => hasGaz && this.products.includes(PRODUCTS.GAZ_CONTROLE)));

    this.$filteredServices.subscribe(fs => (this.filteredServices = fs));
    this.$filteredImmoServices.subscribe(fi => (this.filteredImmoServices = fi));

    this.$isLoggedIn = this.store.select(orderSelector.getIsLoggedIn);

    this.regions = this.dataService.getRegionTypes();
    this.estateTypes = this.dataService.getEstateTypes();
    this.estateStyles = this.dataService.getEstateStyles();

    this.$products.subscribe(p => (this.productsDB = p));

    this.$selectedRegion.subscribe(id => {
      if (this.currentRegionId !== id) {
        this.stepper.selectedIndex = 0;
      }
      this.currentRegionId = id;
    });
    this.$isLoggedIn.subscribe(i => {
      this.isLoggedIn = i;
      if (i && (projectID === 'namur' || projectID === 'hainaut' || projectID === 'liege')) {
        /* setTimeout(() => {
          if (this.dialog.openDialogs.length === 0) {
            this.openServicesChoiceDialog();
          }
        }, 1000); */
      } else if (!i && projectID === 'nvn') {
        setTimeout(() => {
          this.openLoginDialog();
        }, 1000);
      }
    });

    this.$reductions.subscribe(red => {
      let r = '';
      const promodRed = red.find(rr => rr.id === 1002);
      if (promodRed) {
        r = promodRed.price;
      }
      this.reductionPromoCode = r;
    });


    this.maison5 = this.houseOptions?.filter(h => h.typeId === estates.MAISON_5)[0];
    this.maison6 = this.houseOptions?.filter(h => h.typeId === estates.VILLA)[0];


    this.elecOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    this.gazOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

    if (this.estateTypes !== undefined) {
      this.houseOptions = this.estateTypes['m'];
      this.nonResOptions = this.estateTypes['non'];
      this.appartOptions = this.estateTypes['a'];
      this.immeubleOptions = this.estateTypes['i'];
    }
    if (this.estateStyles !== undefined && Array.isArray(this.estateStyles)) {
      this.noPlaceHolderEstateStyles = this.estateStyles?.slice(1);
    }

    combineLatest(
      this.$userRoles,
      this.$selectedEstateType,
      this.$userType,
      this.$selectedRegion,
      this.$reason,
      this.$venteType,
      this.$immoOrCtrlSelected,
      this.$showMoreServices,
      (
        userRoles,
        estateType,
        userType,
        selectedRegion,
        selectedReason,
        selectedVenteType,
        immoOrCtrl,
        showMoreServices
      ) => ({
        userRoles,
        estateType,
        userType,
        selectedRegion,
        selectedReason,
        selectedVenteType,
        immoOrCtrl,
        showMoreServices
      })
    ).subscribe(
      ({
        userRoles,
        estateType,
        userType,
        selectedRegion,
        selectedReason,
        selectedVenteType,
        immoOrCtrl,
        showMoreServices
      }) => {
        this.dataService?.getImmoPacks()?.subscribe(pa => {
          let immoPacksWithPrices = (pa && [...pa]) || [];
          this.estateType = estateType;
          this.logger.log('🐛 ImmoPacks0', immoPacksWithPrices);

          if (selectedRegion !== -1 && estateType !== -1) {
            if (projectID === 'default') {
              immoPacksWithPrices = immoPacksWithPrices.filter(p => p.userRoles.length === 0 && +p.description < 1000);
              // Filtering out notaryApp services like Immovlan (>1000) because we didn't manage packs per apps in backend
              //const packLand = immoPacksWithPrices.find(i => +i.description === 9997);
              //if (packLand) {
              //packLand.services = packLand.services.filter(s => s < 100);
              //}
            }
            this.logger.log('🐛 ImmoPacks1', immoPacksWithPrices);
            if (!this.hasImmoAccess && !this.hasImmoAccessProject && projectID !== 'greenfish') {
              immoPacksWithPrices = immoPacksWithPrices.filter(
                p => p.userTypes.length === 0 || p.userTypes.some(ut => ut === userType) || this.hasAccessAllPack
              );
            }
            if (estateType > 13) {
              immoPacksWithPrices = immoPacksWithPrices.filter(
                p => +p.description > 63 || +p.description === 52 || +p.description === 53
              );
            }
            if (selectedReason > -1) {
              immoPacksWithPrices = immoPacksWithPrices.filter(p =>
                p.services.every(s =>
                  this.filteredServices.some(pr =>
                    s === PRODUCTS.CITERNE_AERIENNE ? pr.id === s || pr.id === PRODUCTS.CITERNE_ENTERREE : pr.id === s
                  )
                )
              );
            }
            immoPacksWithPrices = this.getPackPrice(immoPacksWithPrices as any) as any;
            this.logger.log('🐛 ImmoPacks2', immoPacksWithPrices, immoOrCtrl);
          }

          immoPacksWithPrices =
            immoOrCtrl === 0 || userType == 1689
              ? immoPacksWithPrices &&
                immoPacksWithPrices.sort((a, b) => +a.name - +b.name && +a.description - +b.description)
              : immoPacksWithPrices && immoPacksWithPrices.sort((a, b) => b.totalWithReduc - a.totalWithReduc);
          immoPacksWithPrices = [...immoPacksWithPrices].map(ip => ({
            ...ip,
            services: [...ip.services].sort((a, b) => a - b)
          }));

          this.store.dispatch(new SetPacksWithPricesNoFilt(immoPacksWithPrices));

          if (immoOrCtrl === 0) {
            immoPacksWithPrices = immoPacksWithPrices.filter(i => +i.description === 51);
            this.logger.log('🐛 noImmoPacks', immoPacksWithPrices);
          } else if (immoOrCtrl === 1 && projectID === 'default') {
            immoPacksWithPrices = immoPacksWithPrices.filter(
              i =>
                !(
                  +i.description !== 52 &&
                  +i.description !== 53 &&
                  +i.description !== 54 &&
                  +i.description !== 64 &&
                  +i.description !== 65 &&
                  +i.description !== 66 &&
                  +i.description !== 67
                )
            );
            this.logger.log('🐛 noImmoPacks', immoPacksWithPrices);
          }
          immoPacksWithPrices = [...immoPacksWithPrices].sort((a, b) => a.sortId - b.sortId);
          this.immopacksWithPrices = immoPacksWithPrices;

          this.store.dispatch(new SetPacksWithPrices(immoPacksWithPrices));
        });
      }
    );

    this.$services.subscribe(serv => {
      this.controlLength = this.filteredServices.length;
      this.immoLength = this.filteredImmoServices.length;
      // improve by comparing with getServiceTypes
      this.howManyControlSelected = serv.filter(
        s => s === 1 || s === 4 || s === 6 || s === 9 || s === 15 || s === 28
      ).length;
      this.howManyImmoSelected = serv.length - this.howManyControlSelected;
    });

    combineLatest(this.$selectedEstateType, this.$selectedEstateStyle, this.$nbOfAppartments)
      .pipe(map(results => ({ estateType: results[0], estateStyle: results[1], nbOfApp: results[2] })))
      .subscribe(v => {
        if (v.estateStyle === 0) {
          this.houseControl.setValidators(this.selectNotEmpty);
          this.houseControl.setValue(v.estateType);
        } else if (v.estateStyle === 1) {
          this.appartControl.setValidators(this.selectNotEmpty);
          this.appartControl.setValue(v.estateType);
        } else if (v.estateStyle === 2) {
          this.buildingControl.setValidators(this.selectNotEmpty);
          this.buildingControl.setValue(v.nbOfApp);
        } else if (v.estateStyle === 4) {
          this.nonResControl.setValidators(this.selectNotEmpty);
          this.nonResControl.setValue(v.estateType);
        }
      });

    this.store.select(orderSelector.getAddressZipcode).subscribe(zipcode => {
      // Remove any selected service if it is not available for the current zipcode
      const filteredProducts = this.products.filter(selectedServiceId =>
        this.filteredServices.some(availableService => availableService.id === selectedServiceId)
      );
      const hasRemovedAnyServices = filteredProducts.length !== this.products.length;

      this.store.dispatch(new SetServices(filteredProducts));
      if (hasRemovedAnyServices)
        this.snackBar.open(this.translate.instant('step1.removed-zipcode-restricted-services'));
    });
  }

  checkQueryParams() {
    // /?r=0&e=11&p=1-4&c=ouioui
    this.route?.queryParamMap?.subscribe(params => {
      const orderid = params.get('o') ? params.get('o') : null;
      const diag = params.get('diag') ? params.get('diag') : null;
      const code = params.get('c') ? params.get('c') : null;
      const region = params.get('r') ? +params.get('r') : null;
      let estate = params.get('e') ? +params.get('e') : null;
      const pr = params.get('p') && params.get('p').split('-');
      this.succintStep1 = false;

      if (!!code || !!region || !!estate) {
        this.store.dispatch(new ResetOrderState(null));
      }

      if (diag && diag === 'login') {
        this.openLoginDialog();
      } else if (diag && diag === 'register') {
        this.openRegisterDialog();
      } else if (diag && diag === 'forgot') {
        this.openLoginDialog(false, true);
      }

      if (code) {
        this.setPromoCode(code);
      }

      if (orderid) {
        this.dataService.preloadOrder(orderid);
      }

      const hasElecInUrl = pr && pr.some(p => +p === PRODUCTS.ELEC);
      const hasPebInUrl = pr && pr.some(p => +p === PRODUCTS.PEB);
      if (hasElecInUrl || hasPebInUrl) {
        this.setReasonType(ReasonType.Vente);
      }

      if (!isNaN(region) && [0, 1, 2].some(r => r === region)) {
        this.logger.log('🎨 set region');
        this.setRegion(region);

        if (!isNaN(estate) && [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].some(e => e === estate)) {
          this.logger.log('🎨 set esateType');
          if (pr && pr.length && pr.length === 1 && +pr[0] === PRODUCTS.ELEC) {
            estate = estates.OTHER;
          }
          this.setEstateType(estate);
          if (pr && pr.length > 0 && pr[0]) {
            const hasGazInUrl = pr.some(p => +p === PRODUCTS.GAZ_CONTROLE || +p === PRODUCTS.GAZ_CONTROLE_PERIO);
            if (hasGazInUrl) {
              this.logger.log('hasGazInUrl', hasGazInUrl);
              this.setGazChoice(1);
            }
            this.succintStep1 = true;
            if (!this.dataService.isGreenfish()) {
              this.onlinePayment();
            }
            this.store.dispatch(new SetServices([]));
            pr.forEach(element => {
              const prod = +element;
              if (
                !isNaN(prod) &&
                [
                  PRODUCTS.PEB,
                  PRODUCTS.ELEC,
                  PRODUCTS.CITERNE_AERIENNE,
                  PRODUCTS.CITERNE_ENTERREE,
                  PRODUCTS.PLAN,
                  PRODUCTS.GAZ_CONTROLE,
                  PRODUCTS.GAZ_CONTROLE_PERIO,
                  PRODUCTS.AUDIT,
                  PRODUCTS.ELEC_PHOTOVOLTA,
                  PRODUCTS.RESP_PEB,
                  PRODUCTS.ELEC_NONDOM_MAX125A,
                  PRODUCTS.PHOTODRONE,
                  PRODUCTS.VIDEO_DRONE,
                  PRODUCTS.PLAN3D,
                  PRODUCTS.PEBCHAUFFAGE
                ].some(e => e === prod)
              ) {
                this.logger.log('🎨 set products');
                this.setService(prod, true);
              }
            });
          }
        }
      }
    });
  }

  ngAfterContentInit(): void {}

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  setRegion(id: number) {
    if (id === -1) {
      this.setEstateStyle(-1);
    }
    this.store.dispatch(new SetRegion(id));
  }

  setEstateStyle(style: number) {
    this.store.dispatch(new SetEstateStyle(style));
  }

  private getDefaultEstateType(estateStyle): number {
    switch (estateStyle) {
      case estates.HOUSE:
        return estates.MAISON_2;
      case estates.FLAT:
        return estates.STUDIO;
      case estates.BUILDING:
        return estates.IMMEUBLE_APPART;
      default:
        break;
    }
  }

  setEstateType(id: number, element = null) {
    if (id === estates.DEVIS || id === estates.NON_RESI_OVER_250) {
      this.dialog.open(SpecificRequestDialogComponent, {
        panelClass: ['specific-request-dialog'],
        data: {
          dialog: this.dialog,
          estateId: id
        }
      });
      return;
    } else if (id === -1) {
      this.store.dispatch(new SetServices([]));
    }
    // if (element) {
    //   this.scrollToElement(element);
    // }
    this.store.dispatch(new SetEstateType(id));
  }

  setNbOfAppartments(id: number, element = null) {
    if (this.hasElec) {
      this.dataService.setElecSupp(id);
    }
    if (this.hasGaz) {
      this.dataService.setGazSupp(id);
    }
    // if (element) {
    //   this.scrollToElement(element);
    // }
    this.store.dispatch(new SetNumberOfAppartments(id));
  }

  addService(id: number) {
    return this.products.some(p => p === id) ? null : this.setService(id);
  }

  // add this to a service with logic when adding/removing products
  async setService(id, init = false) {
    if (id === PRODUCTS.PLAQUETTE || id === PRODUCTS.DESCRIPTIF || id === PRODUCTS.SMS) {
      return;
    }
    this.store.dispatch(new SetSplitInvoice(false));

    if (this.succintStep1 && this.estateType === estates.OTHER && !init) {
      this.setEstateType(estates.NONE);
      this.succintStep1 = false;
      return this.store.dispatch(new SetServices([]));
    }

    if (
      id === PRODUCTS.ELEC_NONDOM_OVER125A ||
      id === PRODUCTS.AMIANTE ||
      id === PRODUCTS.NETTJARDIN ||
      id === PRODUCTS.NETTMAISON ||
      id === PRODUCTS.RESP_PEB
    ) {
      this.translate.get('section-services.services.' + id).subscribe((res: string) => {
        this.dialog.open(CustomRequestDialogComponent, {
          panelClass: ['specific-request-dialog'],
          data: {
            dialog: this.dialog,
            title: res,
            serviceId: id,
            needAddress: id === PRODUCTS.RESP_PEB
          }
        });
      });
      return;
    }
    if (id === PRODUCTS.PANEL || id === PRODUCTS.PANEL_SUPPORT) {
      const content = await firstValueFrom(this.translate.get('message-dialog.panel-content'));
      const title = await firstValueFrom(this.translate.get('message-dialog.panel-title'));
      this.dialog.open(MessageDialogComponent, {
        panelClass: ['specific-request-dialog'],
        data: {
          dialog: this.dialog,
          content: content,
          title: title
        }
      });
    }
    if (id === PRODUCTS.PANEL_STANDARD) {
      this.dialog.open(PanelRequestDialogComponent, {
        panelClass: ['specific-request-dialog'],
        data: {
          dialog: this.dialog,
          reason: this.venteType
        }
      });
      return;
    }
    if (id === PRODUCTS.PANEL_XL) {
      this.dialog.open(PanelXlRequestDialogComponent, {
        panelClass: ['specific-request-dialog'],
        data: {
          dialog: this.dialog,
          reason: this.venteType
        }
      });
      return;
    }
    if (id === PRODUCTS.ZYVA) {
      this.dialog.open(VisitZyvaRequestDialogComponent, {
        panelClass: ['specific-request-dialog'],
        data: {
          dialog: this.dialog
        },
        disableClose: true
      });
      return;
    }
    if (id === -101) {
      this.dialog.open(PosterRequestDialogComponent, {
        panelClass: ['poster-request-dialog', 'full-screen-dialog'],
        data: {
          dialog: this.dialog
        },
        disableClose: true
      });
      return;
    }
    if (id === -102) {
      this.dialog.open(SignsRequestDialogComponent, {
        panelClass: ['signs-request-dialog', 'full-screen-dialog'],
        data: {
          dialog: this.dialog
        },
        disableClose: true
      });
      return;
    }
    if (id === -103) {
      this.dialog.open(PaperRequestDialogComponent, {
        panelClass: ['paper-request-dialog', 'full-screen-dialog'],
        data: {
          dialog: this.dialog
        },
        disableClose: true
      });
      return;
    }
    if (id === PRODUCTS.PRINT) {
      this.dialog.open(PrintFullpressRequestDialogComponent, {
        panelClass: ['print-full-press-request-dialog', 'full-screen-dialog'],
        data: {
          dialog: this.dialog
        },
        disableClose: true
      });
      return;
    }
    if (id === PRODUCTS.PRINT_A_LA_CARTE_BIS && projectID === 'hainaut') {
      this.dialog.open(SpecificPrintRequestDialogComponent, {
        panelClass: ['print-request-dialog', 'full-screen-dialog'],
        data: {
          dialog: this.dialog,
          reason: this.venteType
        },
        disableClose: false
      });
      return;
    }
    if (id === PRODUCTS.PRINT_A_LA_CARTE) {
      this.dialog.open(PrintRequestDialogComponent, {
        panelClass: ['print-request-dialog', 'full-screen-dialog'],
        data: {
          dialog: this.dialog,
          reason: this.venteType,
          type: 'lavenir'
        },
        disableClose: true
      });
      return;
    }
    if (id === PRODUCTS.PRINT_A_LA_CARTE_BIS) {
      this.dialog.open(PrintRequestDialogComponent, {
        panelClass: ['print-request-dialog', 'full-screen-dialog'],
        data: {
          dialog: this.dialog,
          reason: this.venteType
        },
        disableClose: true
      });
      return;
    }
    if (id === PRODUCTS.VISIT) {
      this.dialog.open(VisitRequestDialogComponent, {
        panelClass: ['visit-request-dialog', 'full-screen-dialog'],
        data: {
          dialog: this.dialog,
          reason: this.venteType
        },
        disableClose: true
      });
      return;
    }

    this.dataService.setService(id);
  }

  setServiceNoRemove(id) {
    if (
      id === PRODUCTS.PANEL ||
      id === PRODUCTS.PANEL_SUPPORT ||
      id === PRODUCTS.PANELBIDDIT ||
      id === PRODUCTS.PANELBIDDIT_SUPPORT
    ) {
      this.products = this.products?.filter(
        pr =>
          pr !== PRODUCTS.SMS &&
          pr !== PRODUCTS.PANEL &&
          pr !== PRODUCTS.PANEL_SUPPORT &&
          pr !== PRODUCTS.PANELBIDDIT &&
          pr !== PRODUCTS.PANELBIDDIT_SUPPORT
      );
    }

    this.products.push(id);
    this.store.dispatch(new SetServices(this.products));
  }

  setPebSupp(nb) {
    this.dataService.setPebSupp(nb);
  }

  setAuditSupp(nb) {
    this.dataService.setAuditSupp(nb);
  }

  setElecSupp(nb) {
    this.dataService.setElecSupp(nb);
  }

  setGazSupp(nb) {
    this.dataService.setGazSupp(nb);
  }

  setGazChoice(choice: number) {
    this.store.dispatch(new SetGazChoice(choice));
  }

  isCiterneSelected(id: number) {
    const index = this.products.indexOf(id);

    if (id === PRODUCTS.CITERNE_AERIENNE) {
      return index > -1 ? 'accent' : 'primary';
    } else if (id === PRODUCTS.CITERNE_ENTERREE) {
      return index > -1 ? 'accent' : 'primary';
    }
  }

  get citerneType(): number {
    const under = this.products.indexOf(9) > -1;
    const aerial = this.products.indexOf(6) > -1;

    return under ? 9 : aerial ? 6 : 6;
  }

  regionClass(selectedId) {
    const cls = this.currentRegionId === selectedId ? 'region selected' : 'region';
    return cls;
  }

  regionImgUrl(selectedId, fileName) {
    let url = this.assetsFolder;
    url += fileName + '.svg';
    return url;
  }

  estateClass(selectedEstate) {
    const cls = this.currentEstateStyle === selectedEstate ? 'estate selected' : 'estate';
    return cls;
  }

  estateImgUrl(fileName) {
    let url = this.assetsFolder;
    url += fileName;
    return url;
  }

  serviceClass(id: number) {
    if (id === 9) {
      return this.isSelected(6) || this.isSelected(9) ? 'service selected' : 'service';
    }
    if (id === PRODUCTS.PRINT) {
      return this.products.find(s => s === PRODUCTS.FullPress) ? 'service selected' : 'service';
    }
    if (id === PRODUCTS.PRINT_A_LA_CARTE) {
      return this.products.find(s => s >= PRODUCTS.ProximagNamurBW && s <= PRODUCTS.ProximagHainautOutOfOcc)
        ? 'service selected'
        : 'service';
    }
    if (id === PRODUCTS.PRINT_A_LA_CARTE_BIS) {
      return this.products.find(
        s =>
          (s >= PRODUCTS.PublicationVlan && s <= PRODUCTS.PublicationProximagArdennes) ||
          s === PRODUCTS.VlanEditionLocal
      )
        ? 'service selected'
        : 'service';
    }
    if (id === PRODUCTS.VISIT) {
      return this.visitLength > 0 ||
        (this.visitContact.email && this.visitContact.email !== '') ||
        this.products.some(
          idd =>
            idd === PRODUCTS.VISITADVALORIS ||
            idd === PRODUCTS.VisitGil4S ||
            idd === PRODUCTS.VisitGil6S ||
            idd === PRODUCTS.VisitGilBoundless_3M
        )
        ? 'service selected'
        : 'service';
    }
    /* if (id === PRODUCTS.PANEL) {
      return this.isSelected(PRODUCTS.PANEL_SUPPORT) || this.isSelected(PRODUCTS.PANEL)
        ? 'service selected'
        : 'service';
    } else if (id === PRODUCTS.PANELBIDDIT) {
      return this.isSelected(PRODUCTS.PANELBIDDIT) || this.isSelected(PRODUCTS.PANELBIDDIT_SUPPORT)
        ? 'service selected'
        : 'service';
    } */
    return this.isSelected(id) ? 'service selected' : 'service';
  }

  isSelected(id: number): boolean {
    const bool = this.products.indexOf(id) > -1;
    return bool;
  }

  serviceImgUrl(fileName, id) {
    let url = this.assetsFolder;
    if (fileName === null || fileName === '') {
      const serv = services.concat(immoServices as any, servicesSupp, panneauxServices);
      if (serv.find(s => s.id === id)) {
        fileName = serv.find(s => s.id === id).fileName;
        return (url += fileName + '.svg');
      }
    } else if (fileName === null || fileName === '') {
      fileName = 'service-3d';
      return (url += fileName + '.svg');
    }

    url += fileName;

    if (id === PRODUCTS.PANEL) {
      url += this.isSelected(PRODUCTS.PANEL_SUPPORT) || this.isSelected(PRODUCTS.PANEL) ? '-selected.svg' : '.svg';
    } else if (id === PRODUCTS.PANELBIDDIT) {
      url +=
        this.isSelected(PRODUCTS.PANELBIDDIT) || this.isSelected(PRODUCTS.PANELBIDDIT_SUPPORT)
          ? '-selected.svg'
          : '.svg';
    } else if (id === PRODUCTS.VISIT) {
      url +=
        this.visitLength > 0 || (this.visitContact.email && this.visitContact.email !== '') ? '-selected.svg' : '.svg';
    } else {
      url += this.isSelected(id) ? '-selected.svg' : '.svg';
    }

    return url;
  }

  setImmoPack(id: string) {
    let serv = [];
    if (+id === 54) {
      setTimeout(() => {
        this.store.dispatch(new SetSplitInvoice(true));
      }, 1000);
    } else {
      setTimeout(() => {
        this.store.dispatch(new SetSplitInvoice(false));
      }, 1000);
    }
    if (this.packID === parseInt(id, 10)) {
      this.store.dispatch(new ResetPack(null));
      this.store.dispatch(new SetServices(serv));
    } else {
      // this.toggleShowMoreServices(true);
      this.store.dispatch(new SetImmoPack(parseInt(id, 10)));
      this.dataService.getProductsForPack(id + '').subscribe(se => {
        serv = [...se];
        this.store.dispatch(new SetServices(serv.slice().sort((a, b) => a - b)));
        this.setShowPacks(false);
      });
    }
  }

  setPebPartiel(bool: boolean) {
    if (!bool && this.hasPebPartiel) {
      this.setService(PRODUCTS.PEB_Partiel);
    }
    this.pebPartiel = bool;
  }

  addPebPartiel() {
    this.setService(PRODUCTS.PEB_Partiel);
  }

  addPebNb(pebNb: string) {
    this.store.dispatch(new SetPebNb(pebNb));
  }

  setWantsIso(bool: boolean) {
    this.wantsIso = bool;
  }
  nextStep() {
    // this.gtmService.emitClick({ event_target: 'bouton', event_label: 'étape 1 continuer' });
    this.houseControl.markAsTouched();

    if (this.succintStep1) {
      const ref = this.dialog.open(SummaryConfirmDialogComponent, {
        panelClass: 'summary-confirm-dialog',
        data: {
          regionId: this.currentRegionId,
          estateTypeId: this.estateType,
          dialog: this.dialog,
          products: this.products,
          stepper: this.stepper,
          price: this.priceWithoutReduction,
          reduction: this.reduction
        }
      });

      ref.afterClosed().subscribe(result => {
        this.succintStep1 = result;
      });
    }

    if (!this.hasCompletedStep1) {
      this.hasCompletedStep1 = true;
      this.gtmService.emit('order_funnel_step_1_completed');
    }
  }

  onlinePayment() {
    this.store.dispatch(new ToggleOnlinePayment(true));
  }

  urgence() {
    this.store.dispatch(new ToggleUrgence(true));
  }

  setPromoCode(code: string) {
    if (code !== '') {
      this.store.dispatch(new SetPromoCode(code.toLowerCase()));
    }
  }

  setCommission(value: number) {
    this.store.dispatch(new SetCommission(value));
  }

  setShowPacks(value: boolean) {
    if (this.estateType === estates.OTHER) {
      this.setEstateType(estates.NONE);
      this.succintStep1 = false;
      return this.store.dispatch(new SetServices([]));
    }

    this.store.dispatch(new SetShowPacks(value));
  }

  openLoginDialog(toRegister = false, toForgotten = false): void {
    this.dialog.open(LoginDialogComponent, {
      panelClass: ['no-padding-dialog', 'full-screen-dialog'],
      data: { register: toRegister, forgotten: toForgotten, selectedUserType: this.clientType, dialog: this.dialog },
      disableClose: forceConnection
    });
  }

  openGlobalDialog(): void {
    this.dialog.open(GlobalMessageComponent, {
      panelClass: ['full-screen-dialog'],
      data: { dialog: this.dialog }
    });
  }

  openRegisterDialog(): void {
    this.dialog.open(LoginDialogComponent, {
      panelClass: ['no-padding-dialog', 'full-screen-dialog'],
      data: { register: true, dialog: this.dialog },
      disableClose: forceConnection
    });
  }

  openDetailsDialog(id: number, event): void {
    if (event) {
      event.stopPropagation();
    }
    if (id === PRODUCTS.PLAN) {
      const dialogRef = this.dialog.open(ServicesDetailsDialogComponent, {
        panelClass: ['full-screen-dialog'],
        data: { dialog: this.dialog, serviceId: id }
      });
      dialogRef.componentInstance.onAddService.subscribe(data => {
        if (!!data) {
          this.addService(data);
        }
      });
    }
    if (id === PRODUCTS.PHOTO) {
      const dialogRef = this.dialog.open(ServicesDetailsDialogComponent, {
        panelClass: ['full-screen-dialog'],
        data: { dialog: this.dialog, serviceId: id }
      });
      dialogRef.componentInstance.onAddService.subscribe(data => {
        if (!!data) {
          this.addService(data);
        }
      });
    }
    if (id === PRODUCTS.VISITE360) {
      const dialogRef = this.dialog.open(ServicesDetailsDialogComponent, {
        panelClass: ['full-screen-dialog'],
        data: { dialog: this.dialog, serviceId: id }
      });
      dialogRef.componentInstance.onAddService.subscribe(data => {
        if (!!data) {
          this.addService(data);
        }
      });
    }
    if (id === PRODUCTS.VIDEO) {
      const dialogRef = this.dialog.open(ServicesDetailsDialogComponent, {
        panelClass: ['full-screen-dialog'],
        data: { dialog: this.dialog, serviceId: id }
      });
      dialogRef.componentInstance.onAddService.subscribe(data => {
        if (!!data) {
          this.addService(data);
        }
      });
    }
    if (id === PRODUCTS.LIVRET) {
      const dialogRef = this.dialog.open(ServicesDetailsDialogComponent, {
        panelClass: ['full-screen-dialog'],
        data: { dialog: this.dialog, serviceId: id }
      });
      dialogRef.componentInstance.onAddService.subscribe(data => {
        if (!!data) {
          this.addService(data);
        }
      });
    }
    if (id === PRODUCTS.AFFICHETTE) {
      const dialogRef = this.dialog.open(ServicesDetailsDialogComponent, {
        panelClass: ['full-screen-dialog'],
        data: { dialog: this.dialog, serviceId: id }
      });
      dialogRef.componentInstance.onAddService.subscribe(data => {
        if (!!data) {
          this.addService(data);
        }
      });
    }
    if (id === PRODUCTS.PLAQUETTE) {
      const dialogRef = this.dialog.open(ServicesDetailsDialogComponent, {
        panelClass: ['full-screen-dialog'],
        data: { dialog: this.dialog, serviceId: id }
      });
      dialogRef.componentInstance.onAddService.subscribe(data => {
        if (!!data) {
          this.addService(data);
        }
      });
    }
    if (id === PRODUCTS.PANELBIDDIT) {
      const dialogRef = this.dialog.open(ServicesDetailsDialogComponent, {
        panelClass: ['full-screen-dialog'],
        data: { dialog: this.dialog, serviceId: id, venteType: this.venteType }
      });
      dialogRef.componentInstance.onAddService.subscribe(data => {
        if (!!data) {
          this.addService(data);
        }
      });
    }
    if (id === PRODUCTS.PANEL) {
      const dialogRef = this.dialog.open(ServicesDetailsDialogComponent, {
        panelClass: ['full-screen-dialog'],
        data: { dialog: this.dialog, serviceId: id }
      });
      dialogRef.componentInstance.onAddService.subscribe(data => {
        if (!!data) {
          this.addService(data);
        }
      });
    }
    if (id === PRODUCTS.PANEL_XL) {
      const dialogRef = this.dialog.open(ServicesDetailsDialogComponent, {
        panelClass: ['full-screen-dialog'],
        data: { dialog: this.dialog, serviceId: id }
      });
      dialogRef.componentInstance.onAddService.subscribe(data => {
        if (!!data) {
          this.addService(data);
        }
      });
    }
    if (id === PRODUCTS.DESCRIPTIF) {
      const dialogRef = this.dialog.open(ServicesDetailsDialogComponent, {
        panelClass: ['full-screen-dialog'],
        data: { dialog: this.dialog, serviceId: this.currentRegionId === 1 ? PRODUCTS.DESCRIPTIF_URBA : id }
      });
      dialogRef.componentInstance.onAddService.subscribe(data => {
        if (!!data) {
          this.addService(data);
        }
      });
    }
    if (id === PRODUCTS.DESCRIPTIF_URBA) {
      const dialogRef = this.dialog.open(ServicesDetailsDialogComponent, {
        panelClass: ['full-screen-dialog'],
        data: { dialog: this.dialog, serviceId: id }
      });
      dialogRef.componentInstance.onAddService.subscribe(data => {
        if (!!data) {
          this.addService(data);
        }
      });
    }
    if (id === PRODUCTS.SMS) {
      const dialogRef = this.dialog.open(ServicesDetailsDialogComponent, {
        panelClass: ['full-screen-dialog'],
        data: { dialog: this.dialog, serviceId: id }
      });
      dialogRef.componentInstance.onAddService.subscribe(data => {
        if (!!data) {
          this.addService(data);
        }
      });
    }
  }

  displayDetailsBtn(id: number) {
    return (
      id === PRODUCTS.VISITE360 ||
      id === PRODUCTS.PHOTO ||
      id === PRODUCTS.PLAN ||
      id === PRODUCTS.VIDEO ||
      id === PRODUCTS.LIVRET ||
      id === PRODUCTS.AFFICHETTE ||
      id === PRODUCTS.PLAQUETTE ||
      id === PRODUCTS.PANEL ||
      id === PRODUCTS.PANELBIDDIT ||
      (id === PRODUCTS.DESCRIPTIF && this.currentRegionId === 1) ||
      id === PRODUCTS.SMS
    );
  }

  openServicesChoiceDialog(): void {
    const servicesChoiceDialogRef = this.dialog.open(ServicesChoiceDialogComponent, {
      panelClass: ['full-screen-dialog'],
      data: { dialog: this.dialog },
      disableClose: true
    });

    const sub = servicesChoiceDialogRef.componentInstance.login.subscribe(() => {
      this.dialog.closeAll();
      this.openLoginDialog();
    });

    const sub2 = servicesChoiceDialogRef.componentInstance.toNotaryServices.subscribe(() => {
      this.dialog.closeAll();
      this.router.navigate(['/notary-services']);
    });
  }

  getPackPrice(
    immoPack: {
      id: number;
      reduc: string;
      services: number[];
      servicesPrice: number;
      servicesRed: number;
      totalWithReduc: number;
      servicesInfo: { id: number; name: string }[];
    }[]
  ) {
    const tmp = JSON.parse(JSON.stringify(immoPack));
    tmp.forEach(pack => {
      pack.servicesInfo = [];
      pack.servicesPrice = pack.servicesRed = pack.totalWithReduc = 0;

      // Add descriptif for details
      /* if (this.descriptifPacks.some(id => id === +pack.description)) {
        pack.services = [...pack.services, ...[PRODUCTS.DESCRIPTIF, PRODUCTS.DESCRIPTIF_URBA, PRODUCTS.PLAQUETTE]];
      } */

      const serv = pack.services;

      serv.forEach(id => {
        const servPrice = this.dataService.getProductPrice(id);
        let ser = this.dataService.getServiceTypes().find(s => s.id === id);
        if (!ser) {
          ser = this.dataService.getImmoServiceTypes().find(s => s.id === id);
        }

        servPrice.reduc = this.dataService.getStatutReduc(
          parseInt(id, 10),
          servPrice.reduc,
          this.dataService.getProduct(+id),
          this.userType > UserType.Particulier
        );
        const thisPack = tmp.filter(p => p.id === pack.id)[0];
        /* thisPack.servicesPrice = parseInt(servPrice.price, 10);
        thisPack.servicesRed = parseInt(servPrice.reduc, 10); */
        pack.servicesInfo.push(ser);
        if (isNaN(thisPack.servicesPrice)) {
          thisPack.servicesPrice = 0;
        }
        if (isNaN(thisPack.servicesRed)) {
          thisPack.servicesRed = 0;
        }
        thisPack.servicesPrice =
          thisPack.servicesPrice || thisPack.servicesPrice === 0
            ? isNaN(servPrice.price)
              ? thisPack.servicesPrice
              : thisPack.servicesPrice + servPrice.price
            : servPrice.price;
        thisPack.servicesRed =
          thisPack.servicesRed || thisPack.servicesRed === 0
            ? isNaN(servPrice.reduc)
              ? thisPack.servicesRed
              : thisPack.servicesRed + servPrice.reduc
            : servPrice.reduc;
      });
      pack.reduc = this.dataService.getStatutReduc(
        parseInt(pack.description, 10),
        pack.reduc,
        this.dataService.getProduct(+pack.description),
        this.userType > UserType.Particulier
      );
      pack.totalWithReduc =
        projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege'
          ? Math.round((pack.servicesPrice - pack.servicesRed - pack.reduc) * 10) / 10
          : pack.servicesPrice - pack.servicesRed - parseInt(pack.reduc, 10);

      if (projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege') {
        const se = pack.services;
        pack.services = se.sort((a, b) => a - b).filter(s => s < 10000);
        pack.servicesNotary = se.sort((a, b) => a - b).filter(s => s >= 10000);
      }
      pack.sortId = 99;
      if (+pack.description === 54) {
        pack.sortId = 1;
        pack.proPackDisplay = true;
        pack.servicesInfo = [...pack.servicesInfo].map(ps => ({
          ...ps,
          ...this.dataService.getProductPrice(+ps.id)
        }));

        const prodAgency = [...pack.servicesInfo].filter(pr => +pr.id > 17 || +pr.id === PRODUCTS.PLAN);
        const priceForProNoRed = this.priceForProNoRed(prodAgency);

        const immoRed = this.immoReduction(prodAgency);
        pack.totalWithReduc = priceForProNoRed - immoRed - pack.reduc;
      }
    });
    return tmp;
  }

  selectNotEmpty(control: AbstractControl): { [key: string]: any } | null {
    const valid = control.value !== -1;
    return valid ? null : { isEmpty: { valid: false, value: control.value } };
  }

  setClientType(id: number) {
    this.clientType = id;
    if (+id === UserType.Agence || +id === UserType.Notaire || +id === UserType.Installateur) {
      this.openLoginDialog(+id === UserType.Agence);
      return;
    }
    this.store.dispatch(new SetClientType(id));
  }

  setImmoOrCtrl(id: number, element = null) {
    // Redirection to notary orders if necessary
    this.$isNotary.pipe(take(1)).subscribe(isNotary => {
      if (projectID === 'default' && id === 1 && isNotary) {
        if (this.isFromNVN) {
          return (this.document.location.href = `https://order.nvn.certinergie.be?u=${this.userID}`);
        } else if (this.isFromHainaut) {
          // return (this.document.location.href = `https://order.notaires-hainaut.certinergie.be?u=${this.userID}`);
        } else if (this.isFromNamur) {
          // return (this.document.location.href = `https://order.notaires-namur.certinergie.be?u=${this.userID}`);
        }
      } else if (id === 2) {
        if (projectID === 'hainaut') {
          this.dialog.open(SignsRequestDialogComponent, {
            panelClass: ['signs-request-dialog', 'full-screen-dialog'],
            data: {
              dialog: this.dialog
            },
            disableClose: true
          });
          return;
        }
        this.dialog.open(PanelRequestDialogComponent, {
          panelClass: ['specific-request-dialog'],
          data: {
            dialog: this.dialog,
            reason: this.venteType
          }
        });
        return;
      } else if (id === 3) {
        this.router.navigate(['/notary-services']);
        return;
      }
      // if (element) {
      //   this.scrollToElement(element);
      // }
      this.store.dispatch(new SetImmoOrCtrl(id));
    });
  }

  setVenteType(id: string) {
    this.store.dispatch(new SetVenteType(id));
  }

  setReasonType(id: number) {
    this.store.dispatch(new SetReason(id));
  }

  inputZipCode(control: AbstractControl): { [key: string]: any } | null {
    const pattern = new RegExp(/^(?:(?:[1-9])(?:\d{3}))$/);
    const code = control.value + '';
    const valid = code ? code.match(pattern) : false;
    return valid ? null : { isZipCode: { valid: false, value: code } };
  }

  imgUrlRegion(id: number) {
    if (this.regions === null || this.regions === undefined || this.regions.length === 0) {
      return '';
    }
    // test find is a function
    if (typeof this.regions.find !== 'function') {
      return '';
    }
    const fileName = this.regions.find(r => r.id === id).fileName;
    let url = imgagesFolder;
    url += fileName;
    url += '-selected.svg';
    return url;
  }

  onZipChanges(): void {
    this.situationControl.valueChanges.pipe(debounceTime(100)).subscribe(val => {
      const code = val;
      const valid = this.situationControl.valid;
      this.isAvailableZipFlanders = true;
      let region = -1;
      if (valid) {
        if (code >= 1000 && code <= 1299) {
          region = 1;
        } else if ((code >= 1500 && code <= 3999) || (code >= 8000 && code <= 9999)) {
          this.checkIfZipAvailableFlanders(code);
          region = 2;
        } else if ((code >= 1300 && code <= 1499) || (code >= 4000 && code <= 7999)) {
          region = 0;
        }
        this.setRegion(region);
        this.store.dispatch(new SetZipCode(val));
      }
    });
  }

  checkIfZipAvailableFlanders(code) {
    this.isAvailableZipFlanders = false;
    if (
      (code >= 1500 && code < 2000) ||
      //(code >= 2050 && code <= 2160) ||
      //(code >= 2200 && code <= 2240) ||
      //code == 2260 ||
      //code == 2270 ||
      //code == 2288 ||
      //code == 2290 ||
      //(code >= 2500 && code <= 2890) ||
      (code >= 3000 && code <= 3499)
      //(code >= 9000 && code < 10000)
    ) {
      this.isAvailableZipFlanders = true;
    }
  }

  toggleShowMoreServices(bool: boolean) {
    this.store.dispatch(new ShowMoreServices(bool));
    this.logger.log('showMore: ', bool);
  }

  get canUrgence() {
    return (this.products.length === 1 && this.hasPeb) || (this.products.length === 1 && this.hasElec);
  }

  get hasDescriptif() {
    return this.products.find(id => id === PRODUCTS.DESCRIPTIF);
  }

  packHasDescriptif(ids: number[]) {
    return ids.some(id => id === PRODUCTS.DESCRIPTIF);
  }

  toggleImmoPackDetails(id: number) {
    this.immopacksWithPrices = JSON.parse(JSON.stringify(this.immopacksWithPrices));
    this.immopacksWithPrices.find(im => im.id === id).details = !this.immopacksWithPrices.find(im => im.id === id)
      .details;
    this.store.dispatch(new SetPacksWithPrices(this.immopacksWithPrices));
  }

  isLavenir(id: number) {
    return id >= 10050 && id <= 10054;
  }

  toOrdering() {
    this.authService.resetOrderForm();
  }

  get estateStylesParticulier() {
    // test filter is a function
    if (typeof this.estateStyles?.filter !== 'function') {
      return [];
    }
    return this.estateStyles.filter(e => e.id !== 3);
  }

  immoReduction(reductions: any[]) {
    return reductions
      .filter(
        r =>
          r.id < 1000 &&
          r.id !== 1 &&
          r.id !== 11 &&
          r.id !== 4 &&
          r.id !== 8 &&
          r.id !== 6 &&
          r.id !== 9 &&
          r.id !== 15 &&
          r.id !== 16 &&
          r.id !== 51 &&
          r.id !== 55 &&
          r.id !== 56 &&
          r.id !== 57 &&
          r.id !== 17
      )
      .reduce((a, b) => a + +b.reduc, 0);
  }

  priceForProNoRed(productsForAgency: Product[]) {
    return productsForAgency.reduce((a, b) => a + +b.price, 0);
  }

  scrollToElement($element): void {
    setTimeout(() => {
      if (typeof $element.scrollIntoView === 'function') {
        $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }, 100);
  }
}

interface HouseOption {
  buildingType: string;
  id: number;
  text: string;
  typeId: number;
  subOptions?: HouseOption[];
}
