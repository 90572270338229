import { Component } from '@angular/core';

@Component({
    selector: 'app-info-icon',
    template: `
  <svg class="infoLogo" width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
    d="M15.7503 12.8506C14.8398 12.8506 14.1924 13.2282 14.1924 13.7845V21.3553C14.1924 21.8322 14.8399 22.3091 15.7503 22.3091C16.6202 22.3091 17.3284 21.8322 17.3284 21.3553V13.7845C17.3284 13.2282 16.6202 12.8506 15.7503 12.8506Z"
    fill="#507894"
    />
    <path
    d="M15.7504 8.18164C14.8197 8.18164 14.0913 8.83738 14.0913 9.59246C14.0913 10.3475 14.8197 11.0232 15.7504 11.0232C16.6608 11.0232 17.3892 10.3475 17.3892 9.59246C17.3892 8.83745 16.6608 8.18164 15.7504 8.18164Z"
    fill="#507894"
    />
    <path
    d="M15.75 0C7.0444 0 0 6.91509 0 15.4598C0 22.437 4.67725 28.2304 10.9833 30.198L14.5651 35.3833C14.8316 35.769 15.2754 36 15.75 36C16.2246 36 16.6684 35.769 16.9349 35.3833L20.5167 30.198C26.8255 28.2295 31.5 22.434 31.5 15.4598C31.5 6.90968 24.4499 0 15.75 0ZM19.2325 27.6398C18.9064 27.7294 18.6233 27.9295 18.4332 28.2046L15.75 32.0889L13.0668 28.2047C12.8767 27.9295 12.5936 27.7294 12.2675 27.6398C6.89972 26.1657 2.86364 21.3174 2.86364 15.4598C2.86364 8.48602 8.64446 2.8125 15.75 2.8125C22.8555 2.8125 28.6364 8.48602 28.6364 15.4598C28.6364 21.3232 24.5943 26.1674 19.2325 27.6398Z"
    fill="#507894"
    />
</svg>`,
    styles: []
})

export class InfoIconComponent { }
