<div #alert class="alert alert-info alert-dismissible fade show" role="alert">
  <button type="button" class="close" aria-label="Close" (click)="closeAlert()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="row pt-1">
    <app-info-icon></app-info-icon>
    <div class="col-10">
      <ul class="banner-text">
        <li>
          <span>{{ 'comission.info1' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info2' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info3' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info4' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info5' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info6' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info7' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info8' | translate }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
